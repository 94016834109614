.pricetable {
  padding-top:45px;
  padding-bottom:45px;
  background:#f9f9f9;
}

/* Use a wide full screen for small screens like tablets. */
@media (min-width: 768px) and (max-width:992px) {
  .container {
    width: initial;
    padding-left: 2em;
    padding-right: 2em;
  }
}

/* --- Plans ---------------------------- */

.my_planHeader {
  text-align: center;
  color: white;
  padding-top:0.2em;
  padding-bottom:0.2em;
}
.my_planTitle {
  font-size:1.8em;
}
.my_planPrice {
  font-size:1.4em;
}
.my_planDuration {
  margin-top: -0.6em;
}

@media (max-width: 768px) {
  .my_planTitle {
    font-size:small;
  }
}

/* --- Features ------------------------- */
.my_feature {
  line-height:2em;
  background: rgba(163, 29, 39, 1);
  color:white;
  margin-top:0;
}

@media (max-width: 768px) {
  .my_feature {
    text-align: center;
  }
}

.my_featureRow {
  margin-top: 0;
  margin-bottom: 0em;
  border: 0.1em solid rgb(255, 255, 255);
}

/* --- Plan 1 --------------------------- */
.my_plan1 {
  background: rgb(255, 255, 255);
  color:$secondary-color;
}

.my_planHeader.my_plan1 {
  background: rgba(163, 29, 39, 1);
  padding:12px 0 6px 0;
  color:$secondary-color;
  border-bottom: thick solid rgb(163, 29, 39);
  text-transform:uppercase;
}

/* --- Plan 2 --------------------------- */
.my_plan2 {
  background: rgb(250, 250, 250);
  color:$secondary-color;
}

.my_planHeader.my_plan2 {
  background: rgba(163, 29, 39, 1);
  padding:10px 0 8px 0;
  color:$secondary-color;
  border-bottom: thick solid rgb(163, 29, 39);
  text-transform:uppercase;
}

/* --- Plan 3 --------------------------- */
.my_plan3 {
  background: rgba(255, 255, 255, 1);
  color:$secondary-color;
}

.my_planHeader.my_plan3 {
  background: rgba(163, 29, 39, 1);
  padding:10px 0 8px 0;
  color:$secondary-color;
  border-bottom: thick solid rgb(163, 29, 39);
  text-transform:uppercase;
}


.my_planFeature {
  text-align: center;
  font-size: 1.2em;
  padding:0;
}

.my_planFeature i.my_check {
  color: $primary-color;
}

.totalPrice {
  .my_feature,
  .my_planFeature {
    background:$secondary-color;
    color:$primary-color;
    border:1px solid #fff;
  }
  .my_feature {
    line-height:1.4em;
    padding:3px 15px;
  }
}


