html,
body {
  font-family: $font-sans-serif;
  background: $white;
  font-weight: $font-weight-medium;
  font-size: $font-size-base;
  line-height: 1.80em;
  overflow-x: hidden;
  color: $secondary-color;
}

p {
  color: $secondary-color;
}

a {
  outline: none;
  color: $primary-color;
  &:focus,
  &:hover {
    color: $secondary-color;
    text-decoration: none;
    outline: none;
  }
}

ul, ol {
  padding-left: 18px;
  text-align: left;
}

h1 {
  color: $primary-color;
  text-transform: uppercase;
  margin: 0;
  display: block;
  -webkit-animation: .5s .25s fade;
  animation: .5s .25s fade;
}

h2, h3, h4 {
  text-transform: uppercase;
}

// BACKGROUNDS
section.gray {
  background: $secondary-color;
}

section.red {
  background: $primary-color;
}

section.gray-light {
  background: #f9f9f9;
}

section.bg {
  background: url('../../images/home/bg.jpg') no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
  float:left;
  width:100%;
  .cards {
    background: rgba(163, 29, 39, 0.93);
    padding: 80px 0;
    height: 100%;
    width: 100%;
    .card {
      color: white;
      line-height: 24px;
      width: 100%;
      position: relative;
    }
    .title {
      color: $secondary-color;
      font-size: 24px;
      display: block;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }
}

.three-columns-clients,
section.diensten {
  .card {
    min-height: 350px;
  }
}

// TOPBAR
header {
  position: relative;
  background: $white;
  color: $secondary-color;
  width: 100%;
  border-bottom: 10px solid $white;
  -webkit-box-shadow: 0 0px 6px #cfcfcf;
  -moz-box-shadow: 0 0px 6px #cfcfcf;
  box-shadow: 0 0px 6px #cfcfcf;
  &:after {
    content: "";
    background: $primary-color;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    display: block;
  }
  .logo {
    position: relative;
  }
  .logo-container {
    width: 326px;
    height: 56px;
    padding: 0 10px 10px 10px;
    background: white;
    position: absolute;
    z-index: 101;
    top: 70px;
    -webkit-box-shadow: 0 0px 6px #cfcfcf;
    -moz-box-shadow: 0 0px 6px #cfcfcf;
    box-shadow: 0 0px 6px #cfcfcf;
    &:before {
      position: absolute;
      content: "";
      background: #fff;
      top: -9px;
      bottom: 56px;
      left: -10px;
      right: -10px;
    }
  }
  .logo-border {
    width: 306px;
    height: 116px;
    padding-top: 18px;
    background: #fff;
    position: absolute;
    border: 1px solid $primary-color;
    z-index: 102;
    left: 10px;
    &:before {
      position: absolute;
      content: "";
      background: #fff;
      top: -1px;
      bottom: 55px;
      left: -10px;
      right: -10px;
    }
    img.img-responsive {
      display: block;
      margin: 0px auto;
      position: relative;
    }
  }
  ul.contact {
    margin: 21px 0 0 0;
    padding: 0;
    list-style: none;
    float: right;
    li {
      padding-left: 15px;
      float: left;
      border-left: 1px solid #eee;
      text-transform: uppercase;
      &:first-child {
        border-left: 0;
        padding-right: 15px;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}

// PARALLAX HEADER
/*
.parallax {
  background-size: cover;
  background-attachment: fixed;
}
*/
.parallax-container {
  height: 520px;
  overflow: hidden;
}

.image-container {
  width: 100%;
  margin-top: 0px;
  position: relative;
  padding: 0 0 4px 0;
  border-bottom: 1px solid $secondary-color;
}

.image {
  /* background: url('../../images/header/header-contact.jpg');*/
  background-size: 100%;
  background-repeat: no-repeat;
  height: 520px;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  overflow: hidden;

  -webkit-transform: translateZ(-200px) scale(1.2);
  transform: translateZ(-200px) scale(1.2);
  z-index: 3;
}

#bg-home {
  background: url('../../images/header/marketingadvies.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin-top:-70px;
}

#bg-image {
  margin-top:0px;
}

#bg-1 {
  background: url('../../images/header/bg-3.jpg');
  background-size: cover;
  background-position: -50px -75px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin-top: 88px;
  height: 350px;
  width: 100%;
}

// MENU
#menu-container {
  .navbar {
    margin-bottom: 0;
  }
  .navbar-right {
    li {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }

  }
}

// SOCIAL MEDIA
ul.social {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    a {
      height: 32px;
      display: block;
    }
  }
}

a.facebook {
  background: url('../../images/social.png') -77px 0 no-repeat;
  width: 32px;
  height: 32px;
  transition: all 0.3s ease-in-out;
  &:focus,
  &:hover {
    background: url('../../images/social.png') -77px -32px no-repeat;
    width: 32px;
    height: 32px;
  }
}

a.linkedin {
  background: url('../../images/social.png') 0 0 no-repeat;
  width: 32px;
  height: 32px;
  transition: all 0.3s ease-in-out;
  &:focus,
  &:hover {
    background: url('../../images/social.png') 0 -32px no-repeat;
    width: 32px;
    height: 32px;
  }
}

// HOME
.gray.trigger {
  position: relative;
  border-top: 10px solid $secondary-color;
  border-bottom: 10px solid $secondary-color;
  float:left;
  width:100%;
  &:before {
    top: 0px;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 0px;
    left: 0;
    right: 0;
    display: block;
    background: white;
  }
  h2 {
    margin: 0;
    padding: 32px 0;
    text-transform: uppercase;
    text-align: center;
    color: white;
    a:hover,
    a:focus {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.three-columns-home {
  .cards {
    .card {
      min-height: 340px !important;
    }
  }
}

#video-impact {
  width:100%;
  background:#f5f5f5;
  float:left;
  display:block;
  text-align:center;
  padding:70px 0;
  .video {
    display:block;
    .video-container {
      .icon-container {
        i {
          color:white;
        }
      }
    }
    &:hover,
    &:focus {
      .video-container {
        .icon-container {
          i {
            color:$primary-color;
          }
        }
      }
    }
  }
  .video-container {
    position:relative;
    background:black;
    max-width:600px;
    margin:0 auto;
    text-align:center;
    display:block;
    img {
      opacity:0.75;
    }
    .icon-container {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      i {
        font-size:50px;
      }
    }
  }
  h2 {
    margin:0 0 30px 0;
    color:$primary-color;
  }
}

.cards {
  a.card {
    position: relative;
    border: 1px solid white;
    border-bottom: 0;
    padding: 30px;
    color: white;
    float: left;
    width: 100%;
    .fa-arrow-circle-right {
      transition: all 0.2s ease-in-out;
      font-size: 44px;
      width: 44px;
      height: 44px;
      position: absolute;
      bottom: -22px;
      left: 8px;
      right: 0;
      margin: 0 auto;
      z-index: 10;
    }
    div.border {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      overflow: hidden;
      &:before,
      &:after {
        content: "";
        width: 50%;
        height: 1px;
        background: white;
        position: absolute;
        bottom: 0;
      }
      &:before {
        left: -32px;
      }
      &:after {
        right: -32px;
      }
    }
    &:focus,
    &:hover {
      border: 1px solid $secondary-color;
      border-bottom: 0;
      div.border {
        &:before,
        &:after {
          background: $secondary-color;
        }
      }
      .fa-arrow-circle-right {
        color: $secondary-color;
      }
    }
  }
}

// BUTTONS
.btn-primary {
  background: $primary-color;
  color: white;
  border: 1px solid white;
  border-radius: 0;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background: white;
    color: $primary-color;
    border: 1px solid $primary-color;
    transition: all 0.3s ease-in-out;
  }
}
.btn-arrow {
  float:right;
  margin-top:10px;
  background: $primary-color;
  border-bottom-width:3px;
  border-style: solid;
  border-color:darken($primary-color, 10%);
  color: white;
  border-radius: 0;
  padding:10px 52px 10px 15px;
  position:relative;
  font-size:20px;
  text-transform: inherit;
  &:before {
    content:"\f0a9";
    font-family: FontAwesome;
    position:absolute;
    right:15px;
    top:6px;
    font-size:25px;
    color:white;
  }
  &:hover,
  &:focus {
    color:white;
    background:darken($primary-color, 10%);
    border-color:darken($primary-color, 25%);
  }
}

// MAIN STYLES
.text.main {
  padding: 60px 0;
  h2 {
    margin-top: 0;
    font-size: 20px;
    color: $primary-color;
  }
  .intro-text {
    p {
      font-size: 20px;
      line-height: 36px;
      margin:0;
    }
  }
  .intro-contact {
    padding-bottom: 45px;
  }
  .columns {
    margin-top: 45px;
    padding-top: 50px;
    border-top: 1px solid #eee;
  }
  .column {
    padding: 0;
    text-align: justify;
    p {
      margin:0 0 25px 0;
      &:last-of-type {
        margin:0;
      }
    }
  }
  .column-left {
    padding-right: 15px;
  }
  .column-right {
    padding-left: 15px;
  }
}

#intro-clients {
  .intro-text {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

// OVER ONS
.employees {
  padding: 30px 0;
  .employee {
    padding: 30px;
    text-align: center;
    a {
      position: relative;
      background: $secondary-color;
      width: 100%;
      display: block;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      transition: all 0.6s ease-in-out;
      img {
        opacity: 0.9;
      }
      &:hover,
      &:focus {
        background: white;
        img {
          opacity: 1;
          transition: all 0.6s ease-in-out;
        }
      }
      &:after {
        content: "\f0a9";
        font-family: FontAwesome;
        position: absolute;
        font-size: 70px;
        max-width: 180px;
        bottom: 30px;
        padding: 12px 0;
        opacity: 0.6;
        right: 0;
        left: 0;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        transition: all 0.6s ease-in-out;
      }
    }
    a:hover:after {
      opacity: 1;
    }
    h3 {
      color: white;
    }
  }
}

// DIENSTEN
.overzicht-diensten {
  padding: 30px 0 30px 0;
  h2 {
    color: $primary-color;
  }
  h3 {
    font-size: 18px;
    color: $secondary-color;
  }
  .fa-check {
    color: $primary-color;
    text-align: left;
  }
}

section.bg {
  .cards {
    padding-bottom: 20px;
    .card {
      margin-bottom: 60px;
    }
  }
}

// CLIENTS
.client {
  margin-bottom: 30px;
  .client-logo {
    display: block;
    margin-bottom: 15px;
  }
  &:hover {
    .client-logo {
      transition: all 0.3s ease-in-out;
      opacity: 0.8;
    }
  }
}

.contactinfo {
  margin:0;
  float:left;
  li {
    width: 100%;
    float: left;
    color: $primary-color;
    .fa-stack .fa-circle {
      color: $secondary-color;
    }
    .fa-map-marker {
      top: 0px;
      font-size:25px;
    }
    .fa-envelope-o {
      top:-1px;
    }
    .fa-mobile {
      font-size:30px;
      top:0;
    }
    .fa-tags,
    .fa-users,
    .fa-envelope-o{
      font-size: 19px;
    }
    .icon-info {
      margin-left: 15px;
      padding-top: 6px;
      font-size: 18px;
      line-height: 30px;
    }
    &:first-child {
      margin-bottom: 30px;
    }
  }
  li.kvk {
    padding-top: 30px;
  }

}

// FORMULIEREN
.form-control {
  border-radius: 0;
  border-color:#9c9b9b;
  &:focus {
    border: 1px solid $primary-color;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(163, 29, 39, 0.6);
    outline: 0 none;
  }
}

.input-group {
  width: 100%;
}

.input-group-addon {
  width: 40px;
  background: $secondary-color;
  color: white;
  border-color:#9c9b9b;
  .fa-mobile {
    font-size: 18px;
  }
}

// CONTACT
#map-canvas {
  width: 100%;
  height: 450px;
  display: block;
}

.info-container {
  h3 {
    color: $primary-color;
    margin-bottom: 0;
  }
  h4 {
    margin-top: 10px;
  }
}

// FOOTER
footer {
  background: $secondary-color;
  color: white;
  border-top: 10px solid $secondary-color;
  position: relative;
  width: 100%;
  float:left;
  &:before {
    width: 100%;
    height: 1px;
    background: #fff;
    content: "";
    position: absolute;
    top: 9px;
  }
  p, a {
    color: white;
  }
  a:focus,
  a:hover {
    color: $primary-color;
  }
  h3 {
    margin: 37px 0 15px 0;
    color: rgba(255, 255, 255, 0.25);
  }
  h4 {
    color: $primary-color;
    font-weight: $font-weight-bold;
    padding: 0;
    margin: 0 0 5px 0;
  }
  ul.social {
    li {
      margin-top: 10px;
      a {
        font-weight: $font-weight-medium;
        padding-left: 40px;
        padding-top: 2px;
      }
    }
  }
  .form-vraag {
    .btn-primary {
      width: 100%;
    }
  }
  .footer-contact {
    border-bottom: 1px solid white;
    padding-bottom: 30px;
  }
  .copyright {
    padding: 15px 0;
    ul.list-footer {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align:center;
      li {
        border-right: 1px solid white;
        padding-right: 15px;
        margin-right: 15px;
        line-height: 14px;
        display:inline;
        a {
          color: white;
          &:focus,
          &:hover {
            color: $primary-color;
          }
        }
        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
    .copy-small {
      display:block;
      margin-top:3px;
      font-size:15px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

/*
#dagDeel {
  display:none;
}*/
.form-control {
  font-size: 16px;
}

.container {
  .phone,
  .mail {
    display: none;
  }
}

.container-fluid {
  .phone,
  .mail {
    display: block;
    a {
      padding: 0;
      .fa-stack {
        width: 32px;
        height: 36px;
        margin-top: -2px;
      }
      i.fa-stack-2x {
        font-size: 36px;
        color: $secondary-color;
      }
      i.fa-stack-1x {
        font-size: 18px;
        top: -2px;
        color: $primary-color;
      }
      i.fa-envelope-o {
        top:-4px;
      }
    }
  }
}

// REFERENTIES
#referentie {
  padding:60px 0;
  .intro {
    font-size:20px;
    line-height:30px;
    padding-bottom:15px;
    margin-bottom:15px;
    border-bottom:1px solid #eee;
    font-style: italic;
  }
  .content {
    font-style: italic;
  }
  span.naam {
    font-size:20px;
    display:block;
    margin-bottom:5px;
  }
  span.functie {
    font-style:italic;
  }
  a.back {
    display:block;
    margin-top:15px;
  }
}
span.blokIntro {
  font-style:italic;
}
.author {
  position:relative;
  height:250px;
  width:250px;
}
.author:before, .author:after {
  content:'';
  position:absolute;
  height:0;
  border-bottom:20px solid white;
  bottom:0;
}
.author:before {
  left:0;
  width:40px;
  border-right:16px solid transparent;
}
.author:after {
  left:36px;
  right:0;
  border-left:16px solid transparent;
}


