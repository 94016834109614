@media (min-width: 768px) {
  header {
    min-height: 70px;
  }
  h1 {
    font-size: 24px;
    padding: 0 0 24px 0;
  }
  h2 {
    font-size:18px;
  }
  #bg-home {
    height: 320px;
  }
  .parallax-container {
    height:320px;
    overflow:hidden;
  }
  .parallax {
    height:320px
  }
  .outline#menu-container {
    border: 10px solid $primary-color;
    padding-left: 0;
    padding-right: 0;
    margin-top: -45px;
    margin-bottom: 10px;
    position:relative;
  }
  #menu-container {
    .navbar {
      background: $primary-color;
      border: 1px solid white;
      min-height: 88px;
      border-radius: 0;
      .navbar-left {
        li {
          a {
            padding: 34px 14px;
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;
            transition: all 0.5s ease;
          }
        }
        li.dropdown {
          ul.dropdown-menu {
            background:$primary-color;
            color:white;
            border:10px solid $primary-color;
            border-top:0;
            border-radius:0;
            padding:0;
            box-shadow:none;
            li {
              border-left:1px solid white;
              border-right:1px solid white;
              a {
                padding:12px 20px;
                &:hover,
                &:focus {
                  background:rgba(255,255,255,0.1);
                }
              }
              &:last-child {
                border-bottom:1px solid white;
              }
              &.active {
                a {
                  background:rgba(255,255,255,0.1);
                }
              }
            }
            &:before,
            &:after {
              content:"";
              width:10px;
              height:1px;
              background:white;
              position:absolute;
              top:0;
            }
            &:before {
              left:-10px;
            }
            &:after {
              right:-10px;
            }
          }
          &:hover {
            .dropdown-menu {
              display: block;
              margin-top: 0;
            }
          }
        }
      }
      &.navbar-fixed-top {
        border:0;
        .navbar-left {
          li.dropdown {
            ul.dropdown-menu {
              li {
                border:0;
                &:last-child {
                  border:0;
                }
              }
              &:before,
              &:after {
                width:0;
                height:0;
              }
            }
          }
        }
      }
    }
    .navbar-default .navbar-left > .active > a,
    .navbar-default .navbar-left > .active > a:hover,
    .navbar-default .navbar-left > .active > a:focus,
    .navbar-default .navbar-left > li > a:hover,
    .navbar-default .navbar-left > li > a:focus {
      background: rgba(255, 255, 255, 0.1);
    }
    .navbar-brand {
      margin-top: 3px;
    }
    .navbar-right {
      margin-top: 28px;
      margin-right:10px;
      position:relative;
    }
  }
  #intro {
    margin-top: 30px;
    .bas-fontein {
      background:url('/images/home/bas-fontein.png') no-repeat;
      background-size:256px 376px;
      background-position:bottom right;
    }
  }
  section.bg {
    .cards {
      .card {
        min-height:500px;
      }
    }
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 30px;
    padding: 0 0 30px 0;
  }
  h2 {
    font-size:24px;
  }
  #bg-home {
    height: 420px;
  }
  .parallax-container {
    height:420px;
    overflow:hidden;
  }
  .parallax {
    height:420px
  }
  #menu-container {
    .navbar {
      .navbar-left {
        li {
          a {
            padding: 34px 20px;
          }
        }
      }
    }
  }
  #intro {
    margin-top: 70px;
  }
  #home-text {
    padding-bottom:50px;
    p {
      margin:0 0 25px 0;
      &:last-of-type {
        margin:0;
      }
    }
  }
  section.bg {
    .cards {
      .card {
        min-height:440px;
      }
    }
  }
  #referenties {
    .cards {
      .card {
        height:575px;
      }
    }
  }
}

@media (min-width: 1199px) {
  #bg-home {
    height: 520px;
  }
  .parallax-container {
    height:520px;
    overflow:hidden;
  }
  .parallax {
    height:520px
  }
  #menu-container {
    .navbar-left {
      li {
        a {
          padding: 34px 34px !important;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .image-container {
    .image {
      height:300px;
      -webkit-transform: translateZ(-200px) scale(1.2);
      transform: translateZ(-200px) scale(1.2);
    }
  }

}
@media (max-width: 991px) {
  .column-left {
    padding-right:0;
  }
  .column-right {
    padding-left:0;
  }
  .image-container {
    margin-top:88px;
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1.1);
      transform: translateZ(-200px) scale(1.1);
      background-attachment: scroll !important;
      background-position: 0 0 !important;
    }
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 24px;
    margin: 0 0 10px 0;
  }
  h2,
  section.bg .cards .title {
    font-size: 21px;
  }
  header {
    .logo {
      margin-left:15px;
    }
    .logo-container {
      width: 246px;
      height: 38px;
      padding: 0 10px 10px 10px;
      background: white;
      position: absolute;
      z-index: 101;
      top: 50px;
      -webkit-box-shadow: 0 0px 6px #cfcfcf;
      -moz-box-shadow: 0 0px 6px #cfcfcf;
      box-shadow: 0 0px 6px #cfcfcf;
      &:before {
        position: absolute;
        content: "";
        background: #fff;
        top: -6px;
        bottom: 36px;
        left: -10px;
        right: -10px;
      }
    }
    .logo-border {
      width: 226px;
      height: 78px;
      padding-top: 18px;
      background: #fff;
      position: absolute;
      border: 1px solid $primary-color;
      z-index: 102;
      left: 10px;
      &:before {
        position: absolute;
        content: "";
        background: #fff;
        top: -1px;
        bottom: 34px;
        left: -10px;
        right: -10px;
      }
      img.img-responsive {
        max-width: 160px
      }
    }
    ul.contact {
      margin:11px 15px 10px 0;
    }
  }
  #menu-container {
    margin-top: -25px;
    margin-bottom: 15px;
    .navbar-brand {
      padding: 5px 15px 0 15px;
      img {
        max-width: 40px;
      }
    }
    .navbar-default {
      background: $primary-color;
      border-color: $primary-color;
      .navbar-nav > li > a {
        color: white;
      }
      .navbar-nav > .active > a,
      .navbar-nav > .active > a:hover,
      .navbar-nav > .active > a:focus {
        background: $secondary-color;
      }
    }
    .navbar {
      .navbar-left {
        margin: 0 -15px 7.5px -15px;
        li.dropdown {
          ul.dropdown-menu {
            li {
              a {
                color:white;
              }
            }
          }
          &:focus {
            background:transparent;
          }
        }
        .open {
          a {
            &:focus,
            &:hover {
              background:rgba(255,255,255,0.1);
            }
          }
        }
      }
    }
    .navbar-right {
      background: rgba(255, 255, 255, 0.1);
      margin: 0 -15px;
      padding: 15px 0;
      li {
        width: 30px;
        float: left;
        margin-left: 15px;
        &:first-child {
          margin-left: 15px;
        }
      }
    }
    .navbar-toggle {
      background: white;
    }
    .navbar-default .navbar-toggle .icon-bar {
      background: $primary-color;
    }
  }
  .container-fluid {
    .phone,
    .mail {
      a {
        i.fa-stack-1x {
          top:-6px;
        }
      }
    }
  }
  #bg-home {
    height: 220px;
    background-attachment: scroll;
  }
  .parallax-container {
    height:220px;
    overflow:hidden;
  }
  #intro {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  #home-text {
    padding-bottom:20px;
  }
  section.bg .cards {
    padding: 40px 0 0 0;
  }
  .card {
    margin-bottom: 45px;
  }
  .text.main {
    padding:30px 0;
    .column {
      padding:0px;
    }
  }
  .contactinfo {
    float:left;
    margin-bottom:60px;
    margin-left:0;
    .icon {
      width:40px;
    }
    .icon-info {
      width:220px;
      text-align:left;
    }
  }
  .image-container {
    margin-top:50px;
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 0 !important;
    }
  }
  .three-columns-clients .card, section.diensten .card {
    min-height:auto;
  }
  #video {
    .video-container {
      img {
        max-width:100%;
        height:auto;
      }
    }
  }
}
@media (max-width: 480px) {
  html,
  body {
    font-size:18px;
    line-height:1.6em;
  }
  h1 {
    font-size:21px;
  }
  ul {
    padding-left:18px;
  }
  li, p {
    text-align:left;
  }
  .parallax-container {
    height:190px;
  }
  header {
    .logo {
      display:none;
    }
  }
  .overzicht-diensten {
    h2 {
      text-align:left !important;
    }
  }
  .cards {
    a.card {
      padding:15px 15px 30px 15px;
      margin-bottom:46px;
    }
  }
  .text.main {
    padding:40px 0 20px 0;
    h2 {
      text-align:left;
    }
    .columns {
      margin-top:15px;
      padding-top:25px;
    }
  }
  .footer-contact {
    .col-xs-6 {
      width:100%;
    }
    .col-xs-12 {
      display:none;
    }
  }
}

/* TABLETS */

/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
}
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
  .three-columns-home .cards .card {
    min-height:330px !important;
  }
}
@media
(max-device-width: 1280px)
and (orientation: landscape) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
  .three-columns-home .cards .card {
    min-height:330px !important;
  }
}
@media screen
and (device-width: 601px)
and (device-height: 906px)
and (-webkit-min-device-pixel-ratio: 1.331)
and (-webkit-max-device-pixel-ratio: 1.332)
and (orientation: landscape) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
  .three-columns-home .cards .card {
    min-height:330px !important;
  }
}
@media only screen
and (min-device-width: 800px)
and (max-device-width: 1280px)
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: landscape) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
  .three-columns-home .cards .card {
    min-height:330px !important;
  }
}
@media only screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 1.5)
and (orientation: landscape) {
  .parallax-container {
    height:375px;
  }
  .image-container {
    .image {
      height:280px;
      -webkit-transform: translateZ(-200px) scale(1);
      transform: translateZ(-200px) scale(1);
      background-attachment: scroll !important;
      background-position:0 -30px !important;
    }
  }
  #bg-home {
    -webkit-transform: translateZ(-200px) scale(1);
    transform: translateZ(-200px) scale(1);
    background-attachment: scroll !important;
    background-position:0 -30px !important;
  }
  .outline#menu-container {
    position:relative;
  }
  .three-columns-home .cards .card {
    min-height:330px !important;
  }
}