.header-banner {
  width: 100%;
  height: 257px;
  margin-top: 88px;
  position: relative;
  background: #9c9b9b;
  float: left;
  .container {
    position: relative;
  }
  #men {
    background: url(/images/actie/men.png) no-repeat;
    background-position: top center;
    width: 599px;
    height: 760px;
    position: absolute;
    top: 0;
    right: 0;
  }
  h1 {
    max-width: 500px;
    font-size: 55px;
    line-height: 66px;
    margin: 64px 0 0 0;
  }
}

#button-scan {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 280px;
  a {
    background: $primary-color;
    color: white;
    padding: 12px 15px;
    border-radius: 5px;
    border-bottom: 3px solid #8b141d;
    text-align: center;
    display: block;
    max-width: 280px;
    text-transform: uppercase;
  }
}

.content-banner {
  position: relative;
  width: 100%;
  float: left;
  padding: 120px 0 45px 0;
  h2 {
    margin: 45px 0 3px 0;
    color: $primary-color;
  }
  .intro {
    font-size: 20px;
    line-height: 34px;
    color: $primary-color;
    p {
      color: $primary-color !important;
    }
  }
}

.content-banner {
  .column {
    text-align: justify;
  }
  .column-left {
    padding-right: 30px;
    .uitgelicht {
      background: $primary-color;
      display: block;
      padding: 25px;
      margin: 15px 0 0 0;
      h2,
      p {
        color: white;
        margin: 0;
        padding: 0;
      }
      h2 {
        font-size: 36px;
        margin: 0 0 4px 0;
        text-align: left;
      }
    }
  }
  .column-right {
    padding-left: 30px;
  }
  .block-order {
    float: left;
    width: 100%;
    position: relative;
    padding: 159px 0 0 0;
    #guy {
      background: url(/images/actie/guy.png) no-repeat;
      background-position: top right;
      width: 310px;
      height: 242px;
      top: 0;
      right: -50px;
      position: absolute;
    }
  }
  .block-register {
    background: #eee;
    float: left;
    width: 100%;
    margin: 0 0;
    padding: 30px;
    span.title {
      font-size: 20px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 3px;
    }
    span.subtitle, span.subsubtitle {
      color: $primary-color;
      font-size: 36px;
      display: block;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    span.subsubtitle {
      margin: -11px 0 15px;
      font-size: 24px;
    }
    .verplicht {
      float: right;
      font-size: 12px;
    }
  }
}

#computer {
  position: absolute;
  right: 0;
  top: 0;
  width: 586px;
  height: 389px;
  background: url(/images/actie/computer.png) no-repeat;
  background-position: top center;
  background-size: 90%;
}

@media (max-width: 1199px) {
  .header-banner {
    h1 {
      font-size: 50px;
      line-height: 62px;
    }
  }
  .content-banner {
    padding: 70px 0 45px;
    .block-register {
      span.title {
        font-size: 18px;
      }
      span.subtitle {
        font-size: 28px;
      }
    }
  }
  #computer {
    background-size: 75%;
    right: -70px;
  }
}

@media (max-width: 991px) {
  .header-banner {
    h1 {
      margin: 72px 0 0 0;
      max-width: 375px;
      font-size: 40px;
      line-height: 50px;
    }
  }
  .content-banner {
    .column-right {
      padding-left: 0;
    }
    .column-left {
      padding-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .header-banner {
    margin-top: 50px;
  }
  .content-banner {
    padding: 170px 0 45px;
  }
  #computer {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 470px;
    background: url(/images/actie/computer.png) no-repeat;
    background-position: top center;
    background-size: 75%;
  }
}

@media (max-width: 480px) {
  .content-banner {
    padding: 60px 0 45px;
    .block-register {
      span.title {
        font-size: 16px;
      }
      span.subtitle {
        font-size: 22px;
      }
    }
  }
  #computer {
    height: 220px;
  }
}