// COLORS
$primary-color:                 #a31d27;
$secondary-color:               #9c9b9b;

$white:                         #ffffff;
$black:                         #000000;

$font-weight-light:             300;
$font-weight-medium:            400;
$font-weight-bold:              700;

$font-sans-serif:               'Roboto Condensed', sans-serif;

$font-size-base:                16px;